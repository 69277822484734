.cart-card {
  padding-bottom: 3vh;
}

.cart-list-img img{
  width: 16vh;
  height: 16vh;
  border: 1px solid #A2A2A2;
  border-radius: 1vh 1vh 1vh 1vh;
}

.cart-list-body {
  margin: 0 3vw;
}

.card-button-add {
  width: 3.2vh;
  height: 3.2vh;
  border-radius: 100%;
  background-color: #32394D;
  color: white;
  text-align: center;
  display: inline-block;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  font-size: 0.8rem;
  border: none;
}

@media (min-width: 600px) {
  .card-button-add {
    width: 4.2vh;
    height: 4.2vh; 
    font-size: 1.2rem;
  }
}

@media (min-width: 900px) {  
  .cart-list-img img {
    border-radius: 3vh 3vh 3vh 3vh;
    height: 120px;
    width: 120px;
  }

  .cart-list-body {
    font-size: 1.25rem;
  }
}