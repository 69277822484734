/* .NavigationItems {
  list-style: none;
  display: flex;
  flex-flow: column;
  align-items: center;
  top: 50%;
  position: absolute;
  transform: translateY(-50%);
  padding-left: 5vw;
}

@media (min-width: 600px) {
  .NavigationItems {
    padding-left: 3vw;
  }
} */

.NavigationItems {
  list-style: none;
  display: flex;
  flex-flow: column;
  align-items: center;
  top: 50%;
  position: absolute;
  transform: translateY(-50%);
  padding-left: 0;
  width: 100%;
}

.NavigationItemsBottom {
  cursor: pointer;
  list-style: none;
  display: flex;
  flex-flow: column;
  align-items: center;
  bottom: 3%;
  position: absolute;
  padding-left: 0;
}

.NavigationItemsTop {
  list-style: none;
  display: flex;
  flex-flow: column;
  align-items: center;
  Top: 3%;
  position: absolute;
  padding-left: 0;
}

.NavigationItemsTop img {
  width: 50%;
  height: 5vh;
  border: 0px;
  border-radius: 1vh 1vh 1vh 1vh;
}

@media (min-width: 600px) {
  .NavigationItemsTop img  {
    border-radius: 2vh 2vh 2vh 2vh;
    height: 10vh;
  }
}