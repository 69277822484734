.Toolbar {
  height: 2%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color:#E9EBF2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5vh 10vh;
  box-sizing: border-box;
  z-index: 90;
}


.Toolbar nav {
  height: 100%;
}
