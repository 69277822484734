.SideDrawer {
  position: fixed;
  width: 25%;
  max-width: 40%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 200;
  background-color: white;
  box-sizing: border-box;
  transition: transform 0.3s ease-out;
  background-image: linear-gradient(45deg, #780206, #061161);
  background-repeat: repeat-x;
}

/* app-sidebar[data-background-color="man-of-steel"], .off-canvas-sidebar[data-background-color="man-of-steel"] {
  background-image: linear-gradient(45deg, #780206, #061161);
  background-repeat: repeat-x;
} */

.Open {
  transform: translateX(0);
}

.Close {
  transform: translateX(-100%);
}
