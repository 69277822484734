body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #E9EBF2 !important;
  font-family: 'montserratregular' !important;
  src: local('montserratregular'), url(./assets/fonts/montserratregular.ttf) format('woff');
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.full-height-vh {
  height: 100vh !important;
}

.gradient-indigo-purple {
  background-image: linear-gradient(45deg, #303f9f, #7b1fa2);
  background-repeat: repeat-x;
}

.card.card-outline-success {
  border-width: 1px;
  border-style: solid;
  border-color: #0CC27E;
  background-color: transparent;
}

.row-eq-height [class^="col"] {
  display: flex;
  flex-direction: column;
}

.row-eq-height [class^="col"] > div {
  flex-grow: 1;
}

.listing-card {
  border: 0;
  margin-top: 15px;
  box-shadow: 0 6px 0px 0 rgba(0, 0, 0, 0.01), 0 15px 32px 0 rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  text-decoration: none;
  color: black;
  /* padding: 10vh 0; */
}

.button-cart {
  border-radius: 15px 50px !important;
}

.button-addon {
  border-top-left-radius: 50px !important;
  border-top-right-radius: 50px !important;
  border-bottom-right-radius: 50px !important;
  border-bottom-left-radius: 50px !important;
}

.app-center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.app-bottom {
  position: fixed;
  bottom: 0;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.error-message {
  font-size: 2vw;
  color: red
}

.information-xsmall {
  font-size: 0.8rem;
}

.information-small {
  font-size: 1rem;
}

.information-header {
  font-size: 1.3rem;
  font-weight: bold;
}

.disable-selected {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.v-scrolling {
  overflow-y: scroll;
}

/* FOR MARGIN AND PADDING */
.push-up-sm {
  margin-top: 0.5vh;
}

.push-up {
  margin-top: 1vh;
}

.push-up-md {
  margin-top: 3vh;
}

.push-up-lg {
  margin-top: 5vh;
}

.push-bottom-sm {
  margin-bottom: 0.5vh;
}

.push-bottom {
  margin-bottom: 2vh;
}

.push-bottom-md {
  margin-bottom: 3vh;
}

.push-bottom-lg {
  margin-bottom: 5vh;
}

.push-right-sm {
  margin-right: 0.5vw;
}

.push-left-sm {
  margin-left: 0.5vw;
}

.push-right {
  margin-right: 2vw;
}

.push-left {
  margin-left: 2vw;
}

.paragraph-row {
  display: contents;
}

.card-no-border {
  border: none !important;
  margin: 2vh 0;
}

.card-border-bottom {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-bottom: 1px solid #CDCFD4 !important;
  margin: 2vh 0;
}

.listing-card :focus{
  text-decoration: none;
  color: black;
}

.text-center {
  text-align: center;
}

.col-center {
  display: flex;
  justify-content: center;
}

.video-div {
  top: 0;
  left: 0;
  position: fixed;
  z-index: 300;
  width: 100% !important;
  height: 100% !important;
}


/* COLOR */
.white {
  color: white;
}

.black {
  color: black;
}

.gray {
  color: #434959;
}

.col-transparent {
  background-color: transparent !important;
}

.col-primary {
  background-color: #009DA0 !important;
  /* background-color: #CFECF3 !important; */
}

.col-success {
  background-color: #0CC27E !important
}

.col-danger {
  background-color: #FF586B !important
}

.col-info {
  background-color: #1CBCD8 !important;
}

.col-warning {
  background-color: #FF8D60 !important;
}

.col-gold {
  background-color: #cc9900 !important;
}

.col-gray {
  background-color: #434959 !important;
}