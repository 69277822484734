.search-bar {
  margin-bottom: 5vh;
}

.search-element {
  border: 1px solid #A6A9AE;
  color: #75787d;
  padding: 6px 10px;
  display: block;
  width: 100%;
  box-sizing: border-box;
  border-radius: 1.5rem;
}

.logo-search {
  margin: auto;
}

.search-element:focus {
  outline: none;
  box-shadow: 0px 0px 4px #009da0;
}


