/* .menu-img {
  width: 30vh;
  height: 30vh;
  border: 1px solid #A2A2A2;
  border-radius: 1vh 1vh 1vh 1vh;
  margin: auto;
}

.toggle-accordion {
  padding: 0 !important;
  color: black !important;
  text-decoration: none !important;
}

@media (min-width: 600px) {
  .menu-img {
    width: 20vh;
    height: 20vh;
  }
} */


.menu-img {
  width: 30vh;
  height: 30vh;
  border: 1px solid #A2A2A2;
  border-radius: 1vh 1vh 1vh 1vh;
  margin: auto;
}

.toggle-accordion {
  padding: 0 !important;
  color: black !important;
  text-decoration: none !important;
}

@media (min-width: 600px) {
  .menu-img {
    width: 15vh;
    height: 15vh;
  }
}
