/* 
.carousel-indicators{
  margin-bottom: 1vh;
}

.carousel-control-next, .carousel-control-prev {
  opacity: 0;
  filter:alpha(opacity=0);
}

.carousel {
  height: 100% !important;
}

.carousel-indicators li {
  width: 1vh;
  height: 1vh;
  margin: 0.5vh;
  border-radius: 100%;
  background-color: white;
  color: white;
  opacity: 100;
}

.carousel-indicators .active {
  width: 1vh;
  height: 1vh;
  margin: 0.5vh;
  background-color:#FFB000;
}

.vertical-center {
  width: 100%;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  font-size: 1vh;
}

.vertical-center h3 {
  letter-spacing: 0.1vh;
  font-weight: bold;
  font-size: 2vh;
  margin-bottom: 0;
}

.inner {
  height: 100%;
  width: 100%;
  background-color: black;
}

.carousel-inner {
  border-radius: 3vh;
  height: 100vh;
  background-color: transparent;
}

@media (min-width: 600px) {
  .carousel-inner {
    height: 25vh;
    background-color: black;
  }

  .inner {
    height: 25vh;
  }

  .vertical-center {
    font-size: 1.5vh;
  }
  
  .vertical-center h3 {
    font-size: 2.5vh;
  }
}

@media (min-width: 1200px) {
  .carousel-inner {
    height: 30vh;
    background-color: black;
  }

  .inner {
    height: 30vh;
  }

  .vertical-center {
    font-size: 2vh;
  }
  
  .vertical-center h3 {
    font-size: 3vh;
  }
}
 */

.video-top-banner {
  text-align: center;
  color: white;
  background-color: black;
  font-size: 3vh;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 8vh;
  z-index: 1;
}

.carousel-control-prev-icon  {
  background-image: none !important;
}

.carousel-control-next-icon {
  background-image: none !important;
}

.carousel-control-prev {
  z-index: 0 !important;
}

.carousel-control-next {
  z-index: 0 !important;
}

.carousel-indicators li {
  width: 1.5vh !important;
  height: 1.5vh !important;
  margin: 1vw !important;
  border-radius: 100%;
  background-color: white;
  color: white;
  opacity: 100;
}

.carousel-indicators .active {
  width: 1.5vh;
  height: 1.5vh;
  margin: 1vw !important;
  background-color:#FFB000;
}

.carousel-indicators {
  margin-bottom: 12vh;
}

.carousel-inner {
  height: 100vh;
}

.video-h {
  min-height: 100vh !important;
}

.video-layout {
  padding-bottom: 0;
  background-color: black;
}

.button-all-menu {
  background-color: black;
}

.video-description {
  width: 100%;
  color: white;
  font-weight: bold;
  padding-bottom: 2vh;
}

.text-vdescription-header {
  font-size: 1rem;
}

.text-vdescription-small {
  font-size: 0.5rem;
}

@media (min-width: 600px) {
  .video-description {
    width: 100%;
    color: white;
    font-weight: bold;
    padding-bottom: 0;
  }

  .text-vdescription-header {
    font-size: 2rem;
  }
  
  .text-vdescription-small {
    font-size: 1.5rem;
  }

  .carousel-indicators {
    margin-bottom: 6vh;
  }

  .video-top-banner {
    font-size: 5vh;
    height: 13vh;
  }

  .video-layout {
    padding-bottom: 10vh;
    padding-top: 0;
  }
}

@media (min-width: 1200px) {
  .video-description {
    margin-top: 5vh;
  }

  .carousel-indicators {
    margin-bottom: -6vh;
  }

  .video-top-banner {
    font-size: 5vh;
    height: 20vh;
  }

  .video-layout {
    padding-bottom: 20vh;
    padding-top: 10vh;
  }
}
