.inner-expand {
  overflow: hidden;
  transition: max-height 0.2s ease;
}

.round {
  font-weight: bold;
  background-color: #eff5f5;
  border: none;
  outline: none;
  color: black;
  justify-content: space-between;
  align-items: center;
  /* padding: 2% 5%; */
  box-sizing: border-box;
  border-radius: 1.2vh;
  height: 4vh;
}

.round:hover {
  outline: none;
  box-shadow: 0px 0px 4px #009da0;
}

.round:focus {
  outline: none;
}