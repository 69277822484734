.cart-container {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 90%;
  height: 75vh;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background-color: #f5f7fa;
  border-radius: 8px;
  padding-right: 3vw;
}

.cart-list-container {
  height: 75vh;
  overflow-y: scroll;
}

.cart-checkout-container {
  height: 65vh;
  background-color: #32394D;
  margin: 5vh 0vw;
  border-radius: 8px;
}

.cart-list-content {
  padding: 6vh 3vw;
}

.checkout-info {
  padding: 3vh 2vw;
  color: #D7DBE7;
}