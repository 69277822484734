.complete-img {
  width: 100%;
  height: 40%;
  margin-bottom: 5%;
}

.complete-w {
  width: 100%;
}

@media (min-width: 600px) {
  .complete-w {
    width: 30%;
  }
}
