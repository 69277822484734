/* .content {
  margin-left: 10vh;
  margin-right: 10vh;
  margin-top: 12vh;
  margin-bottom: 5vh;
} */

.content {
  margin-left: 10vh;
  margin-right: 10vh;
  margin-top: 12vh;
  margin-bottom: 5vh;
}