.float{
	position:fixed;
	width:40px;
	height:40px;
	bottom:40px;
	right:30px;
	background-color:#0C9;
	color:#FFF;
	border-radius:50px;
	text-align:center;
  box-shadow: 2px 2px 3px #999;
	vertical-align: bottom;
  z-index: 1;
}

.fa-stack-text {
  display: inline-flex;
  align-items: center;
  font-size: 1.5em;
  height: 100%;
}

.fa-stack{
  position:fixed;
  bottom:60px;
	right:25px;
  font-size:15%;
  padding:.3em;
  border-radius:999px;
  line-height:.75em;
  color: white;
  background:rgba(255,0,0,.85);
  text-align: center;
  width:6em;
  height: 16px;
  font-weight:bold;
  z-index: 2;
}

.my-float{
  margin: 0;
  -ms-transform: translate(0%, 0%);
  transform: translate(0%, 45%);
  font-size: 1em !important;
}

@keyframes myfirst {
  0% {background: #339966;}
  50% {background:#0C9;}
  0% {background: #339966;}
}

@-moz-keyframes myfirst /* Firefox */ {
  0% {background: #339966;}
  50% {background:#0C9;}
  0% {background: #339966;}
}

@-webkit-keyframes myfirst /* Safari and Chrome */ {
  0% {background: #339966;}
  50% {background:#0C9;}
  0% {background: #339966;}
}

@media (min-width: 600px) {
  .my-float {
    font-size: 2em !important;
  }

  .float{
    width:80px;
	  height:80px;
    right:60px;
  }

  .fa-stack-text {
    font-size: 3em;
  }

  .fa-stack{
    bottom:100px;
    right:50px;
    font-size:30%;
    padding:.6em;
    width:8em;
    height: 28px;
  }
}
